import React from 'react';
import PromoVideoPlayer from './PromoVideoPlayer';
import { SubscribeButtonLink } from '../../channel';
import { dangerousHTML } from '../../common';
import * as S from './Homepage.styles';
import AppStoreButtons from './AppStoreButtons';
import { Channel } from '../../common/types/Channel';

type SectionProps = {
  hero?: boolean; // is it a hero section?
  edges?: boolean; // is it edge-to-edge with no side indentation on mobile?
  background?: string;
  backgroundImage?: string;
  title?: string;
  subtitle?: string;
  html?: string;
  cta?: string;
  ctaLink?: string;
  video?: string;
  videoCover?: string;
  appleId?: string;
  hasGoogleApp?: boolean;
  children?: string;
  channel?: Channel;
  appButtons?: any;
};

const Section = ({
  hero = false,
  edges = false,
  backgroundImage,
  title,
  subtitle,
  html,
  cta,
  ctaLink,
  video,
  videoCover,
  appleId,
  hasGoogleApp,
  children,
  channel,
  appButtons,
}: SectionProps) => {
  // must have at least one of these or renders null
  if (
    !title &&
    !subtitle &&
    !cta &&
    !html &&
    !video &&
    !appleId &&
    !hasGoogleApp &&
    !children
  ) {
    return null;
  }

  return (
    <S.Section hero={hero} edges={edges} backgroundImage={backgroundImage}>
      {/* titles */}
      {title ? <S.Title hero={hero}>{title}</S.Title> : null}
      {subtitle ? <S.Subtitle hero={hero}>{subtitle}</S.Subtitle> : null}

      {/* promo video */}
      {video ? (
        <PromoVideoPlayer videoUrl={video} videoCover={videoCover} />
      ) : null}

      {/* html content section */}
      {html ? (
        <S.Html
          className="prose text-foreground-primary"
          dangerouslySetInnerHTML={dangerousHTML(html)}
        />
      ) : null}

      {cta ? (
        <SubscribeButtonLink
          padding
          label={cta}
          channel={channel}
          customLink={ctaLink}
        />
      ) : null}

      {channel && appButtons && <AppStoreButtons channel={channel} />}

      {/* container children */}
      {children}
    </S.Section>
  );
};

export default Section;
