import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { media } from '../../layout';
import {
  selectIsPlayerScriptReady,
  createSourcesList,
  setupJwPlayer,
  defaultJwConfig,
} from '../../player';

const jwConfig = {
  ...defaultJwConfig,
  autostart: false,
};

function PromoVideoPlayer({ videoUrl, videoCover, isPlayerScriptReady }) {
  // create playlist of video sources
  const playlist = createSourcesList(
    {
      dash: `${videoUrl}.mpd`,
      hls: `${videoUrl}.m3u8`,
    },
    videoCover
  );

  // hook up jwplayer
  const playerRef = useRef(null);
  useEffect(() => {
    if (isPlayerScriptReady && videoUrl && videoCover) {
      const player = setupJwPlayer(playerRef.current);
      player.setup({ ...jwConfig, playlist: playlist });
      return () => player.remove();
    }
  }, [isPlayerScriptReady, videoUrl, playlist, videoCover]);

  if (!videoUrl || !videoCover || !isPlayerScriptReady) return null;

  return (
    <VideoPlayerContainer>
      <div ref={playerRef} />
    </VideoPlayerContainer>
  );
}

function mapState(state) {
  return {
    isPlayerScriptReady: selectIsPlayerScriptReady(state),
  };
}

const VideoPlayerContainer = styled.div`
  margin-top: ${(props) => props.theme.padding * 3}px;
  margin-left: -${(props) => props.theme.padding * 2}px;
  margin-right: -${(props) => props.theme.padding * 2}px;
  margin-bottom: -${(props) => props.theme.padding * 5}px;

  ${media.medium`
    width: 100%;
    max-width: 718px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    border: ${(props) => props.theme.padding * 2}px solid ${(props) =>
    props.theme.background};
    box-shadow: ${(props) => props.theme.shadow.heavy};
    border-radius: ${(props) => props.theme.radius}px;
  `};

  ${media.large`
    margin-top: ${(props) => props.theme.padding * 8}px;
  `};

  &:first-child {
    margin-top: 0;
  }

  /* jwplayer theme overrides */
  .jwplayer .jw-display-controls .jw-display-icon-container {
    background-color: ${(props) => props.theme.accentColour};
    border-radius: 100px;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;

    .jw-svg-icon-play {
      padding: 8px;
    }
  }
`;

export default connect(mapState)(PromoVideoPlayer);
