import React from 'react';
import { withRouter } from 'next/router';
import { withSubscription } from '../app/withSubscription';
import Homepage from '../app/homepage/components/Homepage';

// TODO why is this like this and not otherwise
const Home = withRouter((props) => <Homepage {...props} />);

// @ts-ignore
export default withSubscription(Home);
