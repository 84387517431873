import React from 'react';
import LayoutContainer from '../../layout/LayoutContainer';
import { dangerousHTML, sizeCloudinaryImage, HelmetIntl } from '../../common';
import { ChannelSidebar } from '../../channel';
import Section from './Section';
import * as S from './Homepage.styles';
import { withSubscription } from '../../user';
// import { Channel } from "../../common";

// type Props = {
//   channel: Channel;
//   isSubscribed: boolean;
// };

function Homepage({ channel, isSubscribed }) {
  if (!channel) return null;

  const { homepage } = channel;

  return (
    <LayoutContainer>
      <HelmetIntl
        titleId="app.meta.about.title"
        descriptionId="app.meta.about.description"
        values={{
          channel: homepage.logoName || channel.name,
          image: channel.backgroundImageUrl ? channel.backgroundImageUrl : null,
        }}
      />
      <S.SectionList>
        <Section
          hero
          backgroundImage={channel.backgroundImageUrl}
          title={homepage.primaryTitle}
          subtitle={homepage.primarySubtitle}
          cta={homepage.primaryCTA}
          ctaLink={homepage.primaryCTALink}
          channel={channel}
        />

        <Section
          title={homepage.videoTitle}
          subtitle={homepage.videoSubtitle}
          video={channel.promoVideoUrl}
          videoCover={sizeCloudinaryImage(
            channel.promoVideoCoverUrl || channel.promoVideoCoverImageUrl || '',
            800
          )}
        />

        <Section title={homepage.bodyHeader} html={channel.info} />

        <Section
          title={homepage.secondaryTitle}
          subtitle={homepage.secondarySubtitle}
          cta={homepage.secondaryCTA}
          ctaLink={homepage.secondaryCTALink}
          channel={channel}
        />

        {channel.isSubscribable ? (
          <Section>
            <ChannelSidebar channel={channel} isSubscribed={isSubscribed} />
          </Section>
        ) : null}

        <Section
          title={homepage.bodyHeader2}
          html={homepage.body2 ? homepage.body2 : null}
        />

        {homepage.testimonials && homepage.testimonials.length > 0 ? (
          <Section>
            {homepage.testimonials.map((quote) => (
              <S.Quote key={quote.name}>
                <blockquote
                  dangerouslySetInnerHTML={dangerousHTML(quote.quote)}
                />
                <cite>
                  <span dangerouslySetInnerHTML={dangerousHTML(quote.name)} />
                  <span dangerouslySetInnerHTML={dangerousHTML(quote.role)} />
                </cite>
              </S.Quote>
            ))}
          </Section>
        ) : null}

        <Section
          title={homepage.tertiaryTitle}
          subtitle={homepage.tertiarySubtitle}
          cta={homepage.tertiaryCTA}
          ctaLink={homepage.tertiaryCTALink}
          appleId={channel.appleId}
          hasGoogleApp={channel.hasGoogleApp}
          channel={channel}
          appButtons={true}
        />

        <Section
          title={homepage.quaternaryTitle}
          subtitle={homepage.quaternarySubtitle}
          cta={homepage.quaternaryCTA}
          ctaLink={homepage.quaternaryCTALink}
          channel={channel}
        />
      </S.SectionList>
    </LayoutContainer>
  );
}

export default withSubscription(Homepage);
